import React from 'react';
import { string, func } from 'prop-types';
import Box, { boxSpaces } from '@nutkit/component-box';
import { Text, textStyles, textWeights } from '@nutkit/component-text';
import Amount, { amountStyle } from '@nutkit/component-amount';
import Icon, { iconSizes } from '@nutkit/component-icon';
import Link from '@nutkit/component-link';
import IconInformation from '@nutkit/component-icon/icons/IconInformation';
import { Marketing } from '@nm-utils-lib-web/routes';
import Loading from '@nutkit/component-loading';
import { getSWRNetworkStatus, STATES as networkStates } from '@nm-utils-lib-web/network/swr';
import useGetCosts from '@nm-pot/common/hooks/useGetCosts';
import Notification, { notificationLevels } from '@nutkit/component-notification';

import { getFeeThreshold, getAverageInvestmentFundCost, getAverageMarketSpread } from '../../../../../helpers';

const TRANSLATION_NAMESPACE = 'try.form.common.investmentStyles.description';

const InvestmentStyleDescription = ({ 'data-qa': dataQa, value, t }) => {
  const [error, setError] = React.useState();
  const { data, isValidating } = useGetCosts({
    onError: e => setError(e)
  });
  const networkStatus = getSWRNetworkStatus({ data, isValidating });
  const isLoading = [networkStates.INITIAL, networkStates.LOADING, networkStates.REVALIDATING].includes(networkStatus);
  const lowerCaseName = value.toLowerCase();
  const description = t(`try.form.common.investmentStyles.${value}.description`);

  return (
    <>
      <Box data-qa={dataQa} space={boxSpaces.SM}>
        <Text textStyle={textStyles.TEXT_2} textWeight={textWeights.BOLD}>
          {t(`${TRANSLATION_NAMESPACE}.title`)}
        </Text>
        <Text textStyle={textStyles.TEXT_2}>{description}</Text>

        {isLoading && !error && <Loading />}
        {!isLoading && (
          <>
            <Text textStyle={textStyles.TEXT_2} textWeight={textWeights.BOLD} noStack>
              {t(`${TRANSLATION_NAMESPACE}.feesTitle`)}{' '}
              <Link href={Marketing.FEES_URL} noColor title={t(`${TRANSLATION_NAMESPACE}.feesLinkTitle`)} isExternal>
                <Icon component={IconInformation} size={iconSizes.SM} />
              </Link>
            </Text>
            <Text textStyle={textStyles.TEXT_2} noStack>
              {t(`${TRANSLATION_NAMESPACE}.feeThreshold`, getFeeThreshold(data && data.fees, lowerCaseName))}
            </Text>
            <Text textStyle={textStyles.TEXT_2} noStack>
              {t(`${TRANSLATION_NAMESPACE}.costs.etf`)}
              <Amount
                data-qa={`${dataQa}__costs-etf`}
                value={getAverageInvestmentFundCost(data && data.costs, lowerCaseName)}
                style={amountStyle.PERCENT}
              />
            </Text>
            <Text textStyle={textStyles.TEXT_2} noStack>
              {t(`${TRANSLATION_NAMESPACE}.costs.spread`)}
              <Amount
                data-qa={`${dataQa}__costs-spread`}
                value={getAverageMarketSpread(data && data.costs, lowerCaseName)}
                style={amountStyle.PERCENT}
              />
            </Text>
          </>
        )}
      </Box>
      {error && (
        <Notification level={notificationLevels.ERROR} dismissable={false} data-qa={`${dataQa}__error`}>
          {t(`${TRANSLATION_NAMESPACE}.errorMessage`)}
        </Notification>
      )}
    </>
  );
};

InvestmentStyleDescription.propTypes = {
  value: string.isRequired,
  'data-qa': string,
  t: func.isRequired
};

InvestmentStyleDescription.defaultProps = {
  'data-qa': 'select-investment-style-description'
};

export default InvestmentStyleDescription;
