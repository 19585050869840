import React from 'react';
import { func, string, shape, bool } from 'prop-types';
import Checkbox from '@nutkit/component-checkbox';
import Icon, { iconSizes } from '@nutkit/component-icon';
import { ButtonBase } from '@nutkit/component-button';
import IconInformation from '@nutkit/component-icon/icons/IconInformation';
import { ModalWithTrigger } from '@nutkit/component-modal';

import CheckboxModal from './CheckboxModal';

const CheckboxWithModal = ({ 'data-qa': dataQa, disabled, label, formik, id }) => {
  const value = formik.values[id];

  const handleChange = ({ isChecked }) => {
    formik.setFieldValue(id, isChecked);
  };
  const labelAndModalTrigger = (
    <>
      <>{label}</>
      <ModalWithTrigger
        trigger={openModal => (
          <ButtonBase data-qa="checkbox-with-modal" onClick={openModal} aria-labelledby={id}>
            <Icon size={iconSizes.SM} component={IconInformation} />
          </ButtonBase>
        )}
      >
        {openModal => <CheckboxModal openModal={openModal} />}
      </ModalWithTrigger>
    </>
  );

  return (
    <Checkbox
      data-qa={dataQa}
      disabled={disabled}
      id={id}
      label={labelAndModalTrigger}
      isChecked={value}
      onChange={handleChange}
      noBorder
    />
  );
};

CheckboxWithModal.propTypes = {
  label: string,
  'data-qa': string,
  disabled: bool,
  formik: shape({ setFieldValue: func, handleBlur: func, values: shape({}) }),
  id: string.isRequired
};

CheckboxWithModal.defaultProps = {
  disabled: false,
  label: '',
  formik: {},
  'data-qa': 'checkbox-with-moddal'
};

export default CheckboxWithModal;
