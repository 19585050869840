import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { FlagsProvider } from '@nm-utils-lib-web/flags';
import { monitor, apps, getAppName } from '@nm-utils-lib-web/monitoring';
import { environments } from '@nm-utils-lib-web/environments';
import Loading from '@nutkit/component-loading';

import '@nutkit/styles';

import './helpers/i18n';
import App from './components/App';

const app = apps.NM_TRY_WEB;
const version = window.NutmegConfig.VERSION;
const environment = window.NutmegConfig.ENVIRONMENT;
const isLocalEnvironment = environment === environments.LOCAL;

monitor.init({
  app: getAppName(app),
  environment,
  disabled: isLocalEnvironment,
  release: `${app}@${version}`,
  tracingSampleRate: parseFloat(window.NutmegConfig.SENTRY_SAMPLE_RATE)
});

ReactDOM.render(
  <Suspense fallback={<Loading overlay />}>
    <Router>
      <FlagsProvider>
        <App />
      </FlagsProvider>
    </Router>
  </Suspense>,
  document.getElementById('root')
);
